import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ID } from '@datorama/akita';
import { iif, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Article, Cart, Price } from '@lobos/common';
import {
  CartHeaderLocalQuery,
  CartHeaderLocalStore,
  CartItemLocalQuery,
  CartItemLocalStore,
  CartLocalService,
  PriceQuery,
  PriceService,
} from '@lobos/library-v2';
import { GelaArticle } from '../catalog/model/gela-article';
import { GelaCartItem } from './model/gela-cart-item.model';
import { CreateGelaCartItemInterface } from './model/create-gela-cart-item.interface';
import { GelaCartHeader } from './model/gela-cart-header.model';

@Injectable({ providedIn: 'root' })
export class GelaCartLocalService<
  T extends GelaCartHeader,
  R extends GelaCartItem,
  S extends CreateGelaCartItemInterface,
> extends CartLocalService<T, R, S> {
  constructor(
    protected override cartItemLocalQuery: CartItemLocalQuery<R>,
    protected override cartItemLocalStore: CartItemLocalStore<R>,
    protected override cartHeaderLocalQuery: CartHeaderLocalQuery<T>,
    protected override cartHeaderLocalStore: CartHeaderLocalStore<T>,
    protected override priceQuery: PriceQuery,
    protected override priceService: PriceService,
    @Inject(LOCALE_ID) locale: string,
  ) {
    super(cartItemLocalQuery, cartItemLocalStore, cartHeaderLocalQuery, cartHeaderLocalStore, priceQuery, priceService, locale);
  }

  override createCartItem(cartItem: S, _: number | ID): Observable<Cart<T, R>> {
    const list = (this.cartItemLocalQuery.getAll() || []).sort((c1: R, c2: R) => c2.shtFixedItemID - c1.shtFixedItemID);
    const itemId = (list[0]?.shtFixedItemID ?? 0) + 1;

    return iif(
      () => this.priceQuery.hasEntity(cartItem.sArticleID),
      this.priceQuery.selectEntity(cartItem.sArticleID),
      this.priceService
        .get(
          cartItem.sArticleID,
          cartItem.sQuantityUnit,
          'CHF', // TODO should by dynamic!
          cartItem.oArticle?.lngSalesPriceUnit > 0 ? cartItem.oArticle.lngSalesPriceUnit : 1,
          cartItem.oArticle?.decFactor1,
          cartItem.oArticle?.decFactor2,
          cartItem.oArticle?.decFactor3,
        )
        .pipe(switchMap(() => this.priceQuery.selectEntity(cartItem.sArticleID))),
    ).pipe(
      switchMap((price: Price | undefined) =>
        of(
          createCartItem<R, GelaArticle>({
            oArticle: cartItem.oArticle as any,
            decQuantity: cartItem.decQuantity,
            decPL1Length: cartItem.decPL1Length,
            decQuantityDelivered: cartItem.decQuantity,
            decQuantityOrdered: cartItem.decQuantity,
            sArticleID: cartItem.sArticleID,
            sArticleName: cartItem.sArticleName,
            sItemText: cartItem.sItemText,
            lngOrderID: CartLocalService.ACTIVE_CART_ID,
            shtFixedItemID: itemId,
            decPrice: price?.decAmountNetFC || 0,
          }),
        ),
      ),
      tap((newCartItem: Partial<R>) => this.cartItemLocalStore.add(newCartItem as R)),
      switchMap(() =>
        of({
          oSalesMaster: this.cartHeaderLocalQuery.getEntity(CartLocalService.ACTIVE_CART_ID) as T,
          oSalesItemList: this.cartItemLocalQuery.getAll(),
        } as Cart<T, R>),
      ),
    );
  }
}

export function createCartItem<T extends GelaCartItem = GelaCartItem, C extends Article = Article>({
  decQuantity = 0,
  decQuantityDelivered = 0,
  decQuantityOrdered = 0,
  decPL1Length = undefined,
  sArticleID = '',
  sArticleName = '',
  sItemText = '',
  lngOrderID = 0,
  shtFixedItemID = 0,
  oArticle = undefined,
  decPrice = 0,
}: {
  decQuantity?: number;
  decQuantityDelivered?: number;
  decQuantityOrdered?: number;
  decPL1Length: number | undefined;
  sArticleID?: ID;
  sArticleName?: string;
  sItemText?: string;
  lngOrderID?: ID;
  shtFixedItemID?: number;
  oArticle?: C;
  decPrice?: number;
}): Partial<T> {
  const fakeId = [lngOrderID, shtFixedItemID].join(',') as ID;
  return {
    fakeId,
    decQuantity,
    decQuantityDelivered,
    decQuantityOrdered,
    decPL1Length,
    sArticleID,
    sArticleName,
    sItemText,
    lngOrderID,
    shtFixedItemID,
    oArticle,
    decPrice,
  } as unknown as Partial<T>;
}
