import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthService, CartItemStore, CartService } from '@lobos/library-v2';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Cart, CartHeader, User } from '@lobos/common';
import { GelaCartItem } from './model/gela-cart-item.model';
import { CreateGelaCartItemInterface } from './model/create-gela-cart-item.interface';
import { HttpClient } from '@angular/common/http';
import { GelaCartHeader } from './model/gela-cart-header.model';
import { ScannerItem } from '../../features/scanner-adapter/scanner-adapter.component';
import { NumberService } from '../number/number.service';
import { GelaArticle } from '../catalog/model/gela-article';

@Injectable({
  providedIn: 'root',
})
export class CartHelperService {
  constructor(
    private cartService: CartService<CartHeader, GelaCartItem, CreateGelaCartItemInterface>,
    private cartItemStore: CartItemStore<GelaCartItem>,
    private authService: AuthService,
    private httpClient: HttpClient,
  ) {}

  public initializeCarts(): Observable<CartHeader[]> {
    return this.authService.authUser$.pipe(
      tap(() => this.cartService.clearCartHeader()),
      switchMap((user: User | undefined) => this.cartService.setActiveId(user!)),
      filter((id: number | ID) => !!id),
      switchMap((id: number | ID) => this.cartService.getCartById(id).pipe(catchError(() => of(undefined)))),
      switchMap(() => this.cartService.getCartHeaders()),
    );
  }

  /**
   * Updates a GelaCartItem and adjusts quantities if necessary.
   * If the current quantity is less than or equal to 0 and there's a positive ordered quantity,
   * the current quantity is set to the ordered quantity.
   * If the cart item is a "Loong Good" (based on certain conditions) and the current quantity
   * is less than or equal to 0 while there's a positive ordered quantity, the current quantity
   * is adjusted based on the ordered quantity and PL1 length. The PL1 length is also scaled up.
   *
   * @param cartItem The GelaCartItem to be updated.
   * @returns An Observable emitting the updated cart after the modifications.
   */
  public updateGelaCartItem(cartItem: GelaCartItem): Observable<Cart<CartHeader, GelaCartItem>> {
    // Check and adjust quantity if necessary
    if (!this.isLoongGood(cartItem) && cartItem.decQuantity <= 0 && cartItem.decQuantityOrdered > 0) {
      cartItem.decQuantity = cartItem.decQuantityOrdered;
    }

    // Check if it's a "Loong Good" and perform adjustments if needed
    if (this.isLoongGood(cartItem) && cartItem.decQuantity <= 0 && cartItem.decQuantityOrdered > 0) {
      cartItem.decQuantity = cartItem.decQuantityOrdered / cartItem.decPL1Length;
      cartItem.decPL1Length = cartItem.decPL1Length * 1000;
    }

    return this.cartService.updateCartItem(cartItem);
  }

  public createItemFromScanner(item: ScannerItem, cartId: number): Observable<ScannerItem | undefined> {
    return this.httpClient.get<GelaArticle>('api/articles/' + item.sArticleID).pipe(
      map((article) => {
        return {
          ...item,
          decQuantity: this.getValidQuantity(
            item.decQuantity || 1,
            article.decQuantityPackage || 1,
            article.shtNoPackageBreak,
            NumberService.getRoundTo(article),
          ),
        } as ScannerItem;
      }),
      switchMap((scannerItem) =>
        this.httpClient.post<Cart<GelaCartHeader, GelaCartItem>>(`api/carts/${cartId}/items/scanner`, scannerItem).pipe(
          tap((cart) => this.cartItemStore.add(cart.oSalesItemList.find((cartItem) => cartItem.sArticleID === item.sArticleID)!)),
          map(() => ({ ...item, isLoading: false, isSuccess: true })),
          catchError((err) => {
            return of({ ...item, hasError: true, isLoading: false, errorMessage: err.error.message });
          }),
        ),
      ),
    );
  }

  private getValidQuantity(quantity: number, step: number, noPackageBreak: number, roundTo: number) {
    if (roundTo ? quantity < roundTo : quantity < step) {
      return roundTo ? roundTo : step;
    }
    if (quantity % step !== 0 && noPackageBreak) {
      return (Math.round(quantity / step) || 1) * step;
    }
    return quantity;
  }

  private isLoongGood(cartItem: GelaCartItem): boolean {
    return !!(cartItem.oArticle && cartItem.oArticle.sPl1LoongGoodArticle && cartItem.oArticle.sPl1LoongGoodArticle.length > 0);
  }
}
