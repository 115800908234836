import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CartHeaderQuery, CartService } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { CreateGelaCartItemInterface } from '../../services/cart/model/create-gela-cart-item.interface';
import { ComnormService } from '../../services/comnorm/comnorm.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { NumberService } from '../../services/number/number.service';
import { GelaCartHeader } from '../../services/cart/model/gela-cart-header.model';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';
import { Decimal } from 'decimal.js';
import { CustomerArticleInterface } from '../../services/customer-article/customer-article.interface';

export enum AddToCartSizes {
  m = 'm',
  l = 'l',
}

@UntilDestroy()
@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToCartComponent implements OnInit, OnChanges {
  @Input() article!: GelaArticle | CustomerArticleInterface;
  @Input() quantity: number | undefined;
  @Input() length: number = 1;
  @Input() size: 'm' | 'l' = AddToCartSizes.m;
  @Input() commission: string | undefined = '';
  @Output() quantityChanged: EventEmitter<number> = new EventEmitter<number>();
  quantityInput: FormControl<number> = new FormControl<number>(1, { nonNullable: true });
  lengthInput: FormControl<number> = new FormControl<number>(1, { nonNullable: true });
  step: number = 1;
  roundTo: number = 1;

  isLoading$ = new BehaviorSubject<boolean>(false);
  changeDebounce: Subject<undefined> = new Subject<undefined>();

  constructor(
    private cartService: CartService<GelaCartHeader, GelaCartItem, CreateGelaCartItemInterface>,
    protected comNormService: ComnormService,
    private cartHeaderQuery: CartHeaderQuery<GelaCartHeader>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['article']) {
      if (this.article.shtNoPackageBreak && this.article.shtNoPackageBreak > 0) {
        this.step = this.article.decQuantityPackage || 1;
      } else {
        this.step = 1;
      }
      this.roundTo = NumberService.getRoundTo(this.article);
      if (this.quantity) {
        this.quantityInput.patchValue(this.quantity, { emitEvent: false });
        return;
      }

      if (this.article.shtNoPackageBreak && this.article.shtNoPackageBreak > 0) {
        this.quantityInput.patchValue(this.article.decQuantityPackage);
        return;
      }

      this.quantityInput.patchValue(this.step);
    }
  }

  ngOnInit(): void {
    this.changeDebounce.pipe(debounceTime(300), untilDestroyed(this)).subscribe(() => this.notifyChange());
  }

  increaseQuantity(e: Event): void {
    e.preventDefault();
    const input = new Decimal(this.quantityInput.value);
    let newValue = new Decimal(this.article.shtNoPackageBreak ? input.plus(this.step) : input.plus(1));
    if (this.article.shtNoPackageBreak && !newValue.modulo(this.article.decQuantityPackage).equals(0)) {
      newValue = new Decimal(newValue.toNearest(this.step));
    } else if (newValue.toNumber() <= 0) {
      newValue = new Decimal(1);
    }
    this.quantityInput.patchValue(newValue.toNumber());
    this.changeDebounce.next(undefined);
  }

  increaseLength(e: Event): void {
    e.preventDefault();
    this.lengthInput.patchValue(new Decimal(this.lengthInput.value).plus(1).round().toNumber());
  }

  decreaseQuantity(e: Event): void {
    e.preventDefault();
    const input = new Decimal(this.quantityInput.value);
    let newValue = new Decimal(this.article.shtNoPackageBreak ? input.minus(this.step) : input.minus(1));
    if (this.article.shtNoPackageBreak && !newValue.modulo(this.article.decQuantityPackage).equals(0)) {
      newValue = new Decimal(newValue.toNearest(this.step));
    } else if (newValue.toNumber() <= 0) {
      newValue = new Decimal(1);
    }
    this.quantityInput.patchValue(newValue.toNumber());
    this.changeDebounce.next(undefined);
  }

  decreaseLength(e: Event): void {
    e.preventDefault();
    this.lengthInput.patchValue(Math.max(new Decimal(this.lengthInput.value).minus(1).round().toNumber(), 1));
  }

  addToCart(article: GelaArticle | CustomerArticleInterface, event: Event): void {
    event.stopPropagation();
    if (this.quantityInput.value <= 0 || (this.article.sPl1LoongGoodArticle?.length && this.lengthInput.value <= 0)) {
      return;
    }

    this.isLoading$.next(true);

    if (!!this.commission) {
      const activeCart = this.cartHeaderQuery.getActive();

      if (activeCart && activeCart.sPreferredCommission !== this.commission) {
        this.cartService
          .updateCartHeader({ ...activeCart, bShownPopUp: true, sPreferredCommission: this.commission })
          .pipe(first(), untilDestroyed(this))
          .subscribe(() => this.createCartItem(article));
      } else {
        this.createCartItem(article);
      }
    } else {
      this.createCartItem(article);
    }
  }

  createCartItem(article: GelaArticle | CustomerArticleInterface): void {
    this.cartService
      .createCartItem({
        decQuantity: this.quantityInput.value,
        sArticleID: article.sArticleID as string,
        sArticleName: article.sDescription,
        sQuantityUnit: article.sQuantityUnitSales,
        oArticle: article as GelaArticle,
        sItemText: '',
        decPL1Length: this.lengthInput.value || undefined,
        sPL1Commission: this.commission ? this.commission : '',
        lngPL1OutlAgreeSalesContractID: undefined,
      })
      .pipe(
        first(),
        finalize(() => this.isLoading$.next(false)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  validQuantity() {
    const newValue = this.quantityInput.value;
    // checks if new quantity is smaller than minimum order size
    if (this.roundTo ? newValue < this.roundTo : newValue < this.step) {
      const minimumValue = this.roundTo ? this.roundTo : this.step;
      this.quantityInput.patchValue(minimumValue);
      this.changeDebounce.next(undefined);
      return;
    }
    // checks if new quantity is not with decimal point
    if (newValue % this.step !== 0 && !this.article.shtNoPackageBreak) {
      const roundedValue = NumberService.roundNumber(newValue, {
        roundTo: this.roundTo ? this.roundTo : undefined,
        direction: this.article.shtRoundUnit === 8 || this.article.shtRoundUnit === 9 ? 'UP' : undefined,
      });
      this.quantityInput.patchValue(roundedValue);
      this.changeDebounce.next(undefined);
      return;
    }
    // if new quantity is valid
    this.changeDebounce.next(undefined);
  }

  validLength() {
    const newLength = this.lengthInput.value;
    if (this.length != newLength) {
      // checks if new length is smaller than 1
      if (newLength < 1) {
        this.lengthInput.patchValue(1);
        return;
      }
      // checks if new length is not with decimal point
      if (newLength % 1 !== 0) {
        this.lengthInput.patchValue(Math.round(newLength));
        return;
      }
    }
  }

  private notifyChange(): void {
    this.quantityChanged.emit(this.quantityInput.value);
  }
}
