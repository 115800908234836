import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'hasError',
  pure: false,
})
export class HasErrorPipe implements PipeTransform {
  public transform(form: UntypedFormGroup, controlName: string): boolean {
    const control: AbstractControl | null = form.get(controlName);
    if (!control) {
      return false;
    }

    return (control.dirty || control.touched) && control.errors !== null;
  }
}
