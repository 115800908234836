import { Inject, Injectable } from '@angular/core';
import { CATALOG_CONFIG, CatalogConfig, ProductService, ProductStore } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { Product } from '@lobos/common';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GelaProductService extends ProductService {
  constructor(protected override store: ProductStore, @Inject(CATALOG_CONFIG) protected override catalogConfig: CatalogConfig) {
    super(store, catalogConfig);
  }

  public getByNavName(navName: string): Observable<Product> {
    return this.getHttp()
      .get<Product>(`${this.catalogConfig.apiUrl}/products/nav-name/${navName}`)
      .pipe(tap((response: Product) => this.store.add(response)));
  }
}
