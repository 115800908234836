import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GelaCartItem } from '../../../services/cart/model/gela-cart-item.model';
import { NumberService } from '../../../services/number/number.service';
import { Decimal } from 'decimal.js';

@UntilDestroy()
@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityComponent implements OnInit, OnChanges {
  @Input() public item!: GelaCartItem;
  @Input() public changeDebouncing: number = 500;

  @Output() public valuesChanged: EventEmitter<{ length: number; quantity: number }> = new EventEmitter<{
    length: number;
    quantity: number;
  }>();

  public quantity: number = 1;
  public valueLabel: string = 'undefined';
  public length: number = 0;
  public lengthLabel: string = 'mm';
  public step: number = 1;
  public lengthStep: number = 10;
  public isLoongGood: boolean = false;
  public noPackageBreak!: number;
  public roundTo: number = 0;

  public changeDebounce: Subject<{ length: number; quantity: number }> = new Subject<{
    length: number;
    quantity: number;
  }>();

  public form!: FormGroup<{ quantity: FormControl<number> }>;

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    this.changeDebounce.pipe(debounceTime(this.changeDebouncing), untilDestroyed(this)).subscribe((value) => {
      this.valuesChanged.emit({ length: value.length, quantity: value.quantity });
    });
  }

  ngOnChanges() {
    this.quantity = this.item.decQuantityOrdered;
    this.valueLabel = this.item.sQuantityUnit;
    this.length = this.item.decPL1Length;
    this.step = this.item.oArticle.decQuantityPackage >= 1 ? this.item.oArticle.decQuantityPackage : 1;
    this.isLoongGood = !!this.item.oArticle.sPl1LoongGoodArticle?.length;
    this.noPackageBreak = this.item.oArticle.shtNoPackageBreak;
    this.roundTo = NumberService.getRoundTo(this.item.oArticle);

    this.form = new FormGroup({
      quantity: new FormControl(1, {
        validators: [Validators.required, Validators.min(NumberService.getRoundTo(this.item.oArticle))],
        nonNullable: true,
      }),
    });

    this.form?.controls.quantity.patchValue(this.quantity, { emitEvent: false });

    if (this.isLoongGood) {
      this.form.controls.quantity.patchValue(Math.round(this.quantity / this.length), { emitEvent: false });
      this.length = Math.round(this.length * 1000);
    }
  }

  public increase(e: Event): void {
    e.preventDefault();
    const input = new Decimal(this.form.controls.quantity.value);
    let newValue = new Decimal(this.item.oArticle.shtNoPackageBreak ? input.plus(this.step) : input.plus(1));
    if (this.item.oArticle.shtNoPackageBreak && !newValue.modulo(this.item.oArticle.decQuantityPackage).equals(0)) {
      newValue = new Decimal(newValue.toNearest(this.step));
    } else if (newValue.toNumber() <= 0) {
      newValue = new Decimal(1);
    }
    this.form.patchValue({ quantity: newValue.toNumber() });
    this.changeDebounce.next({ length: this.length, quantity: newValue.toNumber() });
  }

  public decrease(e: Event): void {
    e.preventDefault();
    const input = new Decimal(this.form.controls.quantity.value);
    let newValue = new Decimal(this.item.oArticle.shtNoPackageBreak ? input.minus(this.step) : input.minus(1));
    if (this.item.oArticle.shtNoPackageBreak && !newValue.modulo(this.item.oArticle.decQuantityPackage).equals(0)) {
      newValue = new Decimal(newValue.toNearest(this.step));
    } else if (newValue.toNumber() <= 0) {
      newValue = new Decimal(1);
    }
    this.form.patchValue({ quantity: newValue.toNumber() });
    this.changeDebounce.next({ length: this.length, quantity: newValue.toNumber() });
  }

  public increaseLength(): void {
    this.length = new Decimal(this.length + this.lengthStep).toNumber();
    this.changeDebounce.next({ length: this.length, quantity: this.form.controls.quantity.value });
  }

  public decreaseLength(): void {
    this.changeDebounce.next({
      length: Math.max(new Decimal(this.length).minus(this.lengthStep).round().toNumber(), this.lengthStep),
      quantity: this.form.controls.quantity.value,
    });
  }

  quantityOut() {
    const newValue = this.form.controls.quantity.value;
    if (this.quantity != newValue) {
      // checks if new quantity is smaller than minimum order size
      if (this.roundTo ? newValue < this.roundTo : newValue < this.step) {
        const minimumValue = this.roundTo ? this.roundTo : this.step;
        this.form.controls.quantity.patchValue(minimumValue);
        this.changeDebounce.next({ length: this.length, quantity: minimumValue });
        return;
      }
      // checks if new quantity is not with decimal point
      if (newValue % this.step !== 0 && !this.noPackageBreak) {
        const roundedValue = NumberService.roundNumber(newValue, {
          roundTo: this.roundTo ? this.roundTo : undefined,
          direction: this.item.oArticle.shtRoundUnit === 8 || this.item.oArticle.shtRoundUnit === 9 ? 'UP' : undefined,
        });
        this.form.controls.quantity.patchValue(roundedValue);
        this.changeDebounce.next({ length: this.length, quantity: roundedValue });
        return;
      }
      // if new quantity is valid
      this.changeDebounce.next({ length: this.length, quantity: newValue });
    }
  }

  lengthOut() {
    const lengthField = this.elementRef.nativeElement.querySelector('#length') as HTMLInputElement;
    const newLength = +lengthField.value;
    if (this.length != newLength) {
      // checks if new length is smaller than 1
      if (newLength < 1) {
        lengthField.value = '1';
        this.changeDebounce.next({ length: 1, quantity: this.form.controls.quantity.value });
        return;
      }
      // checks if new length is not with decimal point
      if (newLength % 1 !== 0) {
        lengthField.value = Math.round(newLength).toString();
        this.changeDebounce.next({ length: Math.round(newLength), quantity: this.form.controls.quantity.value });
        return;
      }
      // if new length is valid
      this.changeDebounce.next({ length: newLength, quantity: this.form.controls.quantity.value });
    }
  }
}
