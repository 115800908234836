import { AfterViewInit, Directive, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * This directive helps to solve issue related to "iOS hover emulation" when first click
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[afterElement]',
})
export class AfterElementDirective implements AfterViewInit {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit() {
    let newAfterHeight: number;
    if (this.document.querySelector('.js-after-el')) {
      const heightTop = parseFloat(getComputedStyle(this.document.querySelector('.js-detail-top')!).getPropertyValue('height'));
      const heightBack = parseFloat(getComputedStyle(this.document.querySelector('.js-detail-back')!).getPropertyValue('height'));
      const heightTitle = parseFloat(getComputedStyle(this.document.querySelector('.js-detail-title')!).getPropertyValue('height'));

      if (window.innerWidth < 990) {
        newAfterHeight = (this.document.querySelector('.js-detail-main')! as HTMLElement).offsetTop + 24;
      } else {
        newAfterHeight = heightTop + heightBack + heightTitle + 40;
      }

      setTimeout(() => {
        (this.document.querySelector('.js-after-el')! as HTMLElement).style.setProperty('--height', newAfterHeight + 'px');
      }, 10);
    }
  }
}
