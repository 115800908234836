import { Component, Input, OnInit } from '@angular/core';
import { AuthService, PriceQuery } from '@lobos/library-v2';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';
import { GelaPrice } from '../../services/catalog/model/gela-price';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';
import { switchMap } from 'rxjs/operators';
import { GelaPriceQuery } from '../../services/catalog/model/gela-price-query';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { CustomerArticleInterface } from '../../services/customer-article/customer-article.interface';

export enum PriceComponentSize {
  s = 's',
  m = 'm',
  l = 'l',
}

@Component({
  selector: 'app-article-price',
  templateUrl: './article-price.component.html',
  styleUrls: ['./article-price.component.scss'],
})
export class ArticlePriceComponent implements OnInit {
  public authUser$ = this.authService.authUser$;
  @Input()
  decPrice: number | undefined;
  @Input() size: 's' | 'm' | 'l' = PriceComponentSize.m;
  @Input() priceScaleType: 'articlepage' | 'last' | 'mobile' | 'flyout' | 'minicard' | 'slider' | 'default' = 'default';

  public sizeWrapperClasses = {
    s: '',
    m: 'font-montserrat font-bold items-center last:mb-7',
    l: 'font-montserrat font-bold items-end mt-0.5 relative z-10',
  };
  public sizePriceClasses = {
    s: '',
    m: 'text-2xl',
    l: 'text-3xl',
  };
  public sizeScalePriceClasses = {
    s: '-ml-1 -mr-1 relative group flex items-center',
    m: '-ml-1 -mr-1 relative group flex items-center',
    l: 'translate-y-1 relative group flex items-center ml-1',
  };
  public priceScaleTypeClasses = {
    last: {
      wrapper:
        'js-flyout px-5 pt-6 pb-5 absolute -bottom-10 bg-white right-0 translate-x-full border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-188px leading-tight',
      image: 'absolute bottom-12 left-0 -translate-x-full',
    },
    mobile: {
      wrapper:
        'js-flyout px-5 pt-6 pb-5 absolute -top-6 bg-white left-1 phone-sml:left-initial phone-sml:right-0 -translate-x-full phone-sml:translate-x-full -translate-y-1/2 md:translate-y-0 border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-188px leading-tight',
      image:
        'absolute top-2/3 right-0 phone-sml:right-initial phone-sml:left-0 -translate-y-1/2 md:translate-y-0 translate-x-full phone-sml:-translate-x-full rotate-180 phone-sml:rotate-0',
    },
    flyout: {
      wrapper:
        'js-flyout js-flyout-card px-5 pt-6 pb-5 absolute -top-8 bg-white left-1 phone-sml:left-initial phone-sml:right-0 -translate-x-full phone-sml:translate-x-full -translate-y-1/2 border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-188px leading-tight',
      image:
        'absolute top-2/3 right-0 phone-sml:right-initial phone-sml:left-0 -translate-y-1/2 md:translate-y-0 translate-x-full phone-sml:-translate-x-full rotate-180 phone-sml:rotate-0',
    },
    minicard: {
      wrapper:
        'js-flyout px-5 pt-5 pb-4 absolute -bottom-12 bg-white right-0 translate-y-1 translate-x-full border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-188px leading-tight',
      image: 'absolute bottom-14 left-0 -translate-y-1 -translate-x-full',
    },
    slider: {
      wrapper:
        'js-flyout px-5 pt-6 pb-5 absolute -top-6 bg-white right-0 translate-x-full -translate-y-1/2 2xl:translate-y-0 border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-188px leading-tight',
      image: 'absolute bottom-60px 2xl:top-8 left-0 -translate-y-1/2 2xl:translate-y-0 -translate-x-full',
    },
    default: {
      wrapper:
        'js-flyout px-5 pt-6 pb-5 absolute -top-6 bg-white right-0 translate-x-full -translate-y-1/2 md:translate-y-0 border border-primary-light z-30 rounded-flyout shadow-flyout bg-white leading-tight',
      image: 'absolute bottom-60px md:top-8 left-0 -translate-y-1/2 md:translate-y-0 -translate-x-full',
    },
  };
  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  private articleChanged$: BehaviorSubject<GelaArticle | CustomerArticleInterface | undefined> = new BehaviorSubject<
    GelaArticle | CustomerArticleInterface | undefined
  >(undefined);
  private lngPL1OutlAgreeSalesContractID$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  oPrice$: Observable<GelaPrice | undefined> = combineLatest([this.articleChanged$, this.lngPL1OutlAgreeSalesContractID$]).pipe(
    switchMap(([article, lngPL1OutlAgreeSalesContractID]) => {
      if (!article) {
        return of(undefined);
      }

      let sPriceKey: string = article.sArticleID.toString();
      sPriceKey = sPriceKey.concat(lngPL1OutlAgreeSalesContractID ? '_' + lngPL1OutlAgreeSalesContractID.toString() : '');

      const priceQuery: GelaPriceQuery = {
        articleId: article.sArticleID,
        unit: article.sQuantityUnitSales,
        currency: 'CHF',
        qty: article.lngSalesPriceUnit > 0 ? article.lngSalesPriceUnit : 1,
        factor1: article.decFactor1,
        factor2: article.decFactor2,
        factor3: article.decFactor3,
        lngPL1OutlAgreeSalesContractID: lngPL1OutlAgreeSalesContractID,
      };

      return iif(
        () => this.priceQuery.hasEntity(sPriceKey),
        this.priceQuery.selectEntity(sPriceKey),
        this.articleHelper.getPriceFromApi(sPriceKey, priceQuery).pipe(switchMap(() => this.priceQuery.selectEntity(sPriceKey))),
      );
    }),
  );

  constructor(private articleHelper: ArticleHelperService, private priceQuery: PriceQuery<GelaPrice>, private authService: AuthService) {}

  ngOnInit() {
    this.authUser$.subscribe(() => {
      // If user changes, trigger a re-fetch of price
      this.articleChanged$.next(this._article);
    });
  }

  private _article: GelaArticle | CustomerArticleInterface | undefined;

  get article(): GelaArticle | CustomerArticleInterface | undefined {
    return this._article;
  }

  @Input() set article(value: GelaArticle | CustomerArticleInterface | undefined) {
    this._article = value;
    this.articleChanged$.next(value);
  }

  private _lngPL1OutlAgreeSalesContractID: number | undefined;

  @Input() set cartItem(oCartItem: GelaCartItem | undefined) {
    this._lngPL1OutlAgreeSalesContractID = oCartItem?.lngPL1OutlAgreeSalesContractID;
    this.lngPL1OutlAgreeSalesContractID$.next(this._lngPL1OutlAgreeSalesContractID);
  }
}
